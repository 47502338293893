










































import { Component } from 'vue-property-decorator';
import TableDialogFormComponent from '@common-src/mixins/table-dialog-form-component';
import { ThingsAttributeEntityModel, ThingsAttributeQueryModel } from '@common-src/entity-model/things-attribute-entity';
import ThingsAttributeService from '@common-src/service/things-attribute';
import ThingsSyncDialog from '../../things-template/things-sync-dialog.vue';
import { SyncType, ThingsEntityType, ThingsFunctionType } from '@common-src/model/enum';

@Component({
    components: {
        'things-sync-dialog': ThingsSyncDialog
    }
})
export default class ThingsAttributeListComponent extends TableDialogFormComponent<ThingsAttributeEntityModel, ThingsAttributeQueryModel> {
    ThingsAttributeEntityModel = ThingsAttributeEntityModel;
    created() {
        this.initTable({ service: ThingsAttributeService, queryModel: new ThingsAttributeQueryModel(), tableColumns: ThingsAttributeEntityModel.getTableColumns() });
        this.getList();
    }

    // upSysncClick() {
    //     (this.$refs.thingsSyncDialog as ThingsSyncDialog).dialogOpen(SyncType.UPLOAD, ThingsEntityType.FUNCTION, ThingsFunctionType.ATTRIBUTE);
    // }

    // downSysncClick() {
    //     (this.$refs.thingsSyncDialog as ThingsSyncDialog).dialogOpen(SyncType.DOWNLOAD, ThingsEntityType.FUNCTION, ThingsFunctionType.ATTRIBUTE);
    // }
}

